<template>
    <Breadcrumb :items="breadcrumbs" />
    <h1 class="title title--main title--page mb-3 text-center">{{ t('signin') }}</h1>
    <form class="form-in" @submit.prevent="login" @keydown="form.onKeydown($event)">
      <img src="@/assets/img/form-bg.png" class="form-in__bg" alt="">
      <div class="form__subtitle">
        Введите данные для входа или
        <router-link :to="{ name: 'register' }" class="link link--small font-wieght-600">{{ t('register') }}</router-link>
      </div>
      <div class="form-group">
        <div class="form-label">{{ t('email') }}</div>
        <input v-model="form.email" type="email" name="email" class="form-control" :class="{ 'is-invalid': form.errors.has('email') }" >
        <div v-if="form.errors.has('email')" v-html="form.errors.get('email')" class="invalid-feedback" />
      </div>
      <div class="form-group">
        <div class="form-label">{{ t('password') }}</div>
        <input v-model="form.password" :type="type" name="password" class="form-control" :class="{ 'is-invalid': form.errors.has('password') }">
        <div @click="showPwd" class="show-pass-btn">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 12C4 12 6.90909 7 12 7C17.0909 7 20 12 20 12C20 12 17.0909 17 12 17C6.90909 17 4 12 4 12Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        <div v-if="form.errors.has('password')" v-html="form.errors.get('password')" class="invalid-feedback"/>
      </div>
      <div class="form-group">
        <router-link :to="{ name: 'password.reset' }" class="link link--small font-wieght-600">{{ t('forgot_password') }}</router-link>
      </div>
      <div class="text-center">
        <button class="btn btn--blue" type="submit" :disabled="form.busy">{{ t('signin') }}</button>
      </div>
    </form>
</template>
<script>
import { ref } from '@vue/runtime-core'
import { useI18n } from 'vue-i18n'
import { useMeta } from 'vue-meta'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import Cookies from 'js-cookie'
import Form from 'vform'

export default {
  setup () {
    const router = useRouter()
    const { t } = useI18n()
    const store = useStore()
    useMeta({
      title: t('signin')
    })

    const type = ref('password')
    const remember = ref(false)
    const form = ref(new Form({
      email: '',
      password: ''
    }))
    function showPwd () {
      if (type.value === 'password') {
        type.value = 'text'
      } else {
        type.value = 'password'
      }
    }
    async function login () {
      const { data } = await form.value.post(process.env.VUE_APP_API_URL + '/signin', form.value)
      // set token
      store.dispatch('auth/saveToken', {
        token: data.token,
        remember: remember.value
      })
      // fetch user data
      await store.dispatch('auth/fetchUser')
      redirect()
    }
    function redirect () {
      const intendedUrl = Cookies.get('intended_url')
      if (intendedUrl) {
        Cookies.remove('intended_url')
        router.push({ path: intendedUrl })
      } else {
        router.push({ name: 'account' })
      }
    }
    return {
      t,
      form,
      type,
      showPwd,
      login,
      breadcrumbs: [
        { name: t('home'), href: '/' },
        { name: t('signin'), href: null, active: true }
      ]
    }
  }
}
</script>
